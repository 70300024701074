@import "variables.scss";

.common-drop-down {

  .required-red {
    color: $now-color;
  }

  .required-red-label {
    .ng-value {
      color: $now-color;
    }
  }

  .required-red-background {
    .ng-select-container {
      background-color: rgba($now-color, 0.185);
    }
  }

  .recommended-yellow-background {
    .ng-select-container {
      background-color: $validation-background-color;
    }
  }

  .name {
    font-size: 12px;
    white-space: nowrap;
    color: $checked-color;
  }

  .ng-select.custom {
    border: 0px;
    min-height: 0px;
    font-family: Regular;
    border-color: $now-color;
  }

  .ng-select.custom .ng-select-container {
    min-height: 0px;
    border-radius: 3px;
    border-color: $border-color;
    font-family: Regular;
    font-size: 14px;
    height: 34px;
  }

  .ng-select.noborder .ng-select-container {
    min-height: 0px;
    border-radius: 3px;
    border: none;
    font-family: Regular;
    font-size: 14px;
    height: 34px;
  }

  .drop-down-icon {
    height: 20px;
    width: 20px;
    margin-right: 5px;
    margin-top: 7px;
  }

}

.common-drop-down-arrow {

  @extend .common-drop-down;

  .ng-select.custom .ng-arrow-wrapper .ng-arrow {
    display: none;
  }

  .ng-select.noborder .ng-arrow-wrapper .ng-arrow {
    display: none;
  }

  .ng-select span.ng-arrow-wrapper:before {
    content: url("/assets/icons/ArrowDown.svg");
    display: inline-block;
    margin-left: -5px;
    margin-top: 10px;
  }

  .ng-select.ng-select-disabled>.ng-select-container {
    background-color: $disabled-color-rgba !important;
  }

  .ng-select.ng-select-disabled .ng-arrow-wrapper {
    display: none;
  }

  &-highlight-border {
    border-radius: 5px;
    border: 2px solid $highlight-border-color !important;
  }    
}

.service-order-type-picker {
  .ng-dropdown-panel-items {
    min-height: 575px;
  }
}


.global-board-dropdown-height {
  .ng-dropdown-panel-items {
    max-height: 500px;
    min-height: 500px;
  }
}

.sub-repatriation-form-medical {
  .required-red-background {
    .ng-select-container {
      background-color: $field-invalid-value-color;
    }
  }
}

.service-order-stakeholder-picker {
  display: flex;
  &-label {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
