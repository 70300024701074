@import "variables.scss";

$output-message-modal-window-height: 900px;

.output-message-modal-window {
  .modal-dialog {
    max-width: 1804px;

    .modal-content {
      width: 1804px;
      height: $output-message-modal-window-height;
    }
  }
}

ng-dropdown-panel.custom {
  z-index: 1056 !important;
}


 .modal {
   z-index: 1056 !important;  
}

.regular-assessment-modal-window {
  .modal-dialog {
    max-width: 1000px;

    .modal-content {
      width: 1000px;
      height: 810px;
    }
  }
}

.output-message-confirm-close-modal-window {
  .modal-dialog {
    max-width: 400px;

    .modal-content {
      width: 400px;
      height: 168px;
    }
  }
}

.task-message-modal-window {
  .modal-dialog {
    max-width: 1560px;
    max-height: 800px;
  }
}

.case-document-attachment-window {
  .modal-dialog {
    max-width: 1130px;
  }
}

.medical-note-modal-window {
  .modal-dialog {
    margin-top: 150px;

    .modal-content {
      width: 600px;
      height: 400px;
    }
  }
}

.top-most-window-class {
  z-index: 2000 !important;

  .modal-dialog {
    margin: unset !important;

    .modal-content {
      border: 1px solid $checked-color !important;
    }
  }
}
.top-most-window-class-zoomed {
  z-index: 2000 !important;

  .modal-dialog {
    margin: unset !important;

    .modal-content {
      border: 1px solid $checked-color !important;
      position: fixed;
      top: 400px;
      left: 1350px;
      width: 600px;
    }
  }
}

.stakeholder-search-type-popup {
  .modal-dialog {
    margin: unset !important;

    .modal-content {
      position: absolute;
      margin: 50% auto auto;
      width: 600px;
    }
  }
}
.stakeholder-search-type-popup-zoomed {
  .modal-dialog {
    margin: unset !important;

    .modal-content {
      position: fixed;
      left: 1350px;
      width: 600px;
    }
  }
}

.case-type-popup {
  .modal-dialog {
    margin: unset !important;

    .modal-content {
      position: fixed;
      top: 150px;
      left: 220px;
      width: 270px;
      height: 180px;
      overflow: hidden;
    }
  }
}

.channel-type-popup {
  .modal-dialog {
    .modal-content {
      min-width: 270px;
      width: 270px;
      height: 180px;
    }
  }
}

.case-handler-popup {
  .modal-dialog {
    margin: unset !important;

    .modal-content {
      position: fixed;
      top: 170px;
      left: 1350px;
      width: 310px;
      height: 265px;
      overflow: hidden;
    }
  }
}
