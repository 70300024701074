

@font-face {
  font-family: Regular;
  src: url(/assets/fonts/Effra_Rg.ttf) format('truetype'),
}
@font-face {
  font-family: Bold;
  src: url(/assets/fonts/Effra_Bd.ttf) format('truetype'),
}

@font-face {
  font-family: Italic;
  src: url(/assets/fonts/Effra_It.ttf) format('truetype'),
}
@font-face {
  font-family: Medium;
  src: url(/assets/fonts/Effra_Md.ttf) format('truetype'),
}
@font-face {
  font-family: SemiBold;
  src: url(/assets/fonts/Effra_Md.ttf) format('truetype'),
}
