@import 'variables.scss';

.custom-radio-button {
  display: block;
  position: absolute;
  padding-left: 20px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;


}

.radio-disabled {
  .checkmark {
    background-color: $inactive-color;
  }

  .radio-button-text {
    color: $inactive-color;
  }
}

.custom-radio-button input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: white;
  border-color: $inactive-color;
  border-style: solid;
  border-width: thin;
  border-radius: 50%;
}

.custom-radio-button input:checked~.checkmark {
  background-color: $primary-color;
  border-color: $primary-color;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-radio-button input:checked~.checkmark:after {
  display: block;
}

.custom-radio-button .checkmark:after {
  width: 16px;
  height: 16px;
  background: $primary-color;
  border-color: white;
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
}
