@import "variables.scss";
@import "table-variables.scss";

.primary-table {
  width: 100%;
  font-family: Regular;
  font-size: 14px;
  margin-top: 10px;
  border-collapse: separate;
  border-spacing: 0;

  .checkbox-column-header {
    width: 40px;
  }

  .checkbox-column-header:after {
    opacity: 0;
  }

  .checkbox-header-container {
    position: absolute;
    height: 28px;
    width: 24px;
    background-color: white;
    z-index: 100;

    span {
      left: -2px;
      position: absolute;
    }
  }

  .center {
    text-align:center;
    vertical-align:middle;
 }


  .checkbox-column {
    width: 40px;

    .checkbox-content-container {
      width: 19px;

      span {
        top: -17px;
        position: absolute;
      }
    }
  }

  .link-column {
    color: $primary-color;
    cursor: pointer;
  }

  tbody tr:nth-child(even) {
    background-color: $alternate-table-row-color;
  }

  thead tr {
    color: $primary-color;

    td,
    th {
      padding-left: 10px;
      border-top: 3px solid $content-background-color;
      border-bottom: 3px solid $content-background-color;
      border-left: 1px solid $content-background-color;
      border-right: 1px solid $content-background-color;
      height: 43px;
      padding-top: 6px;
      cursor: pointer;
    }

    td:after,
    th:after {
      float: right;
      content: url('/../../assets/icons/SortArrows.svg');
      margin-right: 5px;
      margin-top: 2px;
    }

    .two-lines-table-header-cell:after {
      margin-top: -10px;
    }

    td.sorted-by,
    th.sorted-by {
      background-color: $item-header-background-color;
    }
  }

  tbody tr {
    td {
      border: 1px solid $content-background-color;
      border-top: 3px solid $content-background-color;
      padding-left: $primary-table-cell-padding-left-size;
      height: 55px;
      padding-top: 8px;
    }
  }
}

.fixed-header-table {
  table-layout: fixed;
  border-collapse: collapse;
  border-bottom: 1px solid $border-color;

  thead {
    tr {
      display: block;
      position: relative;
    }
  }

  tbody {
    display: block;
    overflow: auto;
    width: 100%;

    td {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
