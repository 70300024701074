@import "variables.scss";

.output-management-entry {
  
  background-color: $item-header-background-color;

  &-header {
    height: 28px;
    line-height: 28px;

    &-direction {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  &-line {
    position: relative;
    z-index: 0;
    left: -8px;
    height: 1px;
    width: calc(100% + 16px);
    margin-bottom: 5px;
    background-color: $border-color;
  }

  &-title {
    font-size: 10px;
    color: $checked-color
  }

  &-value {
    font-size: 14px;
    padding-bottom: 3px;
  }

  .stakeholder-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  
    &+div {
      margin-top: 2px;
    }
  }
}
