$menu-background-color: #1E212A;
$primary-color: #23AFDC;
$border-color:#E8E8E8;
$selected-color: #DDE5EB;
$content-background-color:#F8F7F7;
$inactive-color: #BDC7D0;
$active-color: #FFE5AA;
$inactive-tab-color: #F0F8FC;
$now-color:#F50A37;
$success-color:#34D1AA;
$success-text-bg:#c2f1e5;
$item-header-background-color: #EEF2F5;
$checked-color:#787878;
$alternate-row-color: #F6F8FA;
$alternate-table-row-color:#fbfbfb;
$icon-color: #FFC046;
$notes-background-color: #FAFAFA;
$circle-color: #FFFFFF;
$circle-border-first-color:#A3EAD9;
$circle-border-second-color:#C5F1FF;
$focus-inner-color:#9bbcf3;
$focus-outer-color:#a6c7ff;
$focus-text-input-color: #EEF8FF;
$validation-failed-color:#ffcccc;
$validation-attention-color:#ffe5aa;
$validation-attention-border-color:#909090;
$tab-active:#ffffff;
$tab-inactive:#eef2f5;
$tab-background:#cde6f5;
$validation-background-color:#fff6e2;
$left-menu-width: 250px;
$right-menu-width: 71px;
$top-panel-height: 100px;
$top-tabs-height: 75px;
$case-summery-note: #eef2f5;
$case-summery-history-even-row: #fbfbfb;
$case-summary-history-border-color: #E8E8E8;
$menu-selected-color: #eeeeee;
$case-summery-border-shadow: #0000000a;
$disabled-color:#f9f9f9;
$toogle-switch-background-color: #cbcbcb;
$standard-text: #000000;
$dropdown-shadow:#959595;
$modal-box-shadow: #00000029;
$dropdown-focus-color: #F5FAFF;
$selected-row-color: #F1FBFF;
$field-invalid-value-color: #FDD1DA;
$content-valid-background-color: #f0fcf9;
$medical-color: #f0fcf9;
$medical-light-blue: #f1fbff;
$medical-medium-blue: #b2e3f3;
$medical-dark-blue: #23afdc;
$link-blue: #23AFDC;
$medical-light-yellow: #ffefcb;
$medical-red: #fff2f5;
$sort-column-selected-color: #EEF2F5;
$disabled-color-rgba: rgba(#BDC7D0, 0.2);
$task-without-case-color:#FFF5F7;
$selected-item-color: #ebf5ff;
$consent-request-red-background: #F41F48;
$consent-request-yellow-background: #FFCD5B;
$ghost-color:#dae6ed;
$ghost-gradient-color: #f2f3f5;
$border-shadow: #0000000A;
$validation-background-color: #fff9e7;
$document-tag-color: #337ab7;
$profile-lookup-btn-color: #ffd36f;
$case-details-repatriation-form-color:#f50a37;
$case-details-escort-color:#ffc000;
$case-details-oxygen-color:#327aff;
$case-details-equipment-color:#00318d;
$case-details-mortal-color:#21a7ac;
$global-board-modal-header-color:#1c8eb3;
$highlight-border-color: #23AFDC;
$warning-color: #ffcc4d;
$case-closed-color:#00318d;
$claims-yellow: #FFD36F;
$cost-control-purple: #8f5d9e;
$investigation-dark-blue: #00318d;
$batch-billing-light-blue: #b2e3f3;
$assistance-and-claims-green: #34d1aa;
$medical-pre-assessment-orange: #fe7e3f;
$pre-departure-travel-service-orange: #fe7e3f;
$awaiting-case-type-black: #000000;
$case-details-outpatient-color:#f9dede;
$case-details-inpatient-color:#f39da0;
$sanction-case-red: #F41F48;
$internal-coordination-dark-green: #059b6e;
$medical-flight-authorization-orange: #fe7e3f;
$major-incident-brown: #b97a57;