@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import "bootstrap/scss/bootstrap";
@import "~flag-icon-css/css/flag-icon.min.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "variables.scss";
@import "fonts.scss";
@import "board.scss";
@import "table.scss";
@import "drop-down.scss";
@import "permissions.scss";
@import "case-summary.scss";
@import "modal-dialog.scss";
@import "button.scss";
@import "service-order.scss";
@import "action-button.scss";
@import "radio-button.scss";
@import "snow.scss";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

.popover {
  max-width: 400px;
  .popover-body{
    padding: 0.5rem;
  }
}

.ng-dropdown-panel { display: block !important; }

.ng-dropdown-panel-wrap {
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    white-space: normal !important;
  }
}

html {
  height: 100%;
}

body {
  background-color: $content-background-color;
  font-family: Regular;
  overflow: hidden;
  height: 100%;
  font-size: 14px;
}

.nav-tabs {
  border-bottom: 0;
}

.nav-tabs .nav-item {
  background-color: white;
  border-radius: 8px 8px 0px 0px;
  margin-right: 3px;
  height: 34px;
  border: 1px solid $border-color;
  box-shadow: 0 -6px 6px -6px $border-color, 6px 0 6px -6px $border-color, -6px 0 6px -6px $border-color;
  -moz-box-shadow: 0 -6px 6px -6px $border-color, 6px 0 6px -6px $border-color, -6px 0 6px -6px $border-color;
  -webkit-box-shadow: 0 -6px 6px -6px $border-color, 6px 0 6px -6px $border-color, -6px 0 6px -6px $border-color;
  margin-top: 3px;
}

.nav-tabs .nav-item:first-of-type {
  margin-left: 0px;
}

.tab-pane {
  background-color: white;
  padding: 0px;
  padding-top: 10px;
}

.nav-tabs .nav-link {
  font-family: Regular;
  font-size: 15px;
  margin-top: 2px;
  height: 33px;
  color: $inactive-color;
  padding-top: 2px;
  padding-left: 10px;
  padding-right: 10px;
  border-color: transparent !important;

  .close-tab-x {
    color: black;
    display: inline-block;
    height: 25px;
    width: 25px;
    cursor: pointer;
    padding-left: 8px;
    padding-right: 0px;

    img {
      height: 14px;
      width: 14px;
    }
  }
}

.nav-tabs .nav-link.active {
  font-family: SemiBold;
  border-radius: 8px 8px 0 0;
  font-size: 15px;
  border: 0;
  margin-top: 3px;
}

.tab-content {
  border: 1px solid $border-color;
  border-top: none;
  background-color: white;
  -moz-box-shadow: 0 0px 6px 0 $border-color;
  box-shadow: 0 0px 6px 0 $border-color;
  -webkit-box-shadow: 0 0px 6px 0 $border-color;
}

.shortcut-key {
  font-family: SemiBold;
  text-decoration: underline;
}

.custom-control-label {
  padding-top: 2px;
  font-size: 12px;
  margin: 10px 10px 0px 0px;
}

.fixed-width-40 {
  flex: 0 0 40px;
  padding: 0px;
}

.custom-checkbox {
  .checkbox {
    display: block;
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .checkbox .label {
    position: absolute;
    right: 25px;
  }

  /* Hide the browser's default checkbox */
  .checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 2px;
    right: 0;
    height: 15px;
    width: 15px;
    background-color: #fff;
    border: 1px solid $inactive-color;
    border-radius: 3px;
  }

  .checkmark-big {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 20px;
    width: 20px;
  }

  .checkbox:hover input~.checkmark {
    background-color: #ddd;
  }

  .checkbox input:focus~.checkmark {
    border: 1px solid $focus-inner-color;
    outline: 1px solid $focus-outer-color;
    border-radius: 3px;
  }

  .checkbox input:checked~.checkmark {
    background-color: $primary-color;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .checkbox input:disabled~.checkmark {
    background-color: $inactive-color;
  }

  /* Show the checkmark when checked */
  .checkbox input:checked~.checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkbox .checkmark:after {
    right: 3px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .checkbox .checkmark-big:after {
    right: 5px;
    top: 3px;
  }
}
.col-2,
.col-4,
.col-6 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.col-8 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.col-3 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.col-12 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.show-more {
  text-align: center;
  color: $primary-color;
  font-family: Regular;
  font-size: 14px;
  cursor: pointer;

  img {
    margin-left: 7px;
  }
}

.cdk-overlay-container {
  z-index: 2001 !important;
}

.pac-container {
  z-index: 2001 !important;
}

.bg-info {
  background-color: $primary-color !important;
}

.ng-dropdown-footer {
  padding: 0px !important;
  margin: 0px !important;
  border-width: 0px !important;
}

.mat-snack-bar-container {
  max-width: 66vw !important;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  padding-right: 25px;
}

.ng-select {
  .ng-dropdown-panel {
    width: auto !important;
    min-width: 100%;
  }
}

.success {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: $success-color;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 9 l-1.5 1.5 5 5 8 -8 -1.5 -1.5 -6.5 6.5z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
  background-repeat: no-repeat;
}

.success-yellow {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: $active-color;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 9 l-1.5 1.5 5 5 8 -8 -1.5 -1.5 -6.5 6.5z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
  background-repeat: no-repeat;
}

.failure {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: $now-color;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 9 l0 2 14 0 0 -2z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
}

.warn {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: $active-color;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 2 l0 10 2 0 0 -10 -2 0 M9 14 l0 2 2 0 0 -2z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
}

.unset {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: $inactive-color;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 9 l0 2 14 0 0 -2z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
}

.empty-gray-ring {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: $inactive-color;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='10' cy='10' r='8' fill='%23fff' stroke='%23BDC7D0' stroke-width='0' fill-rule='nonzero' /%3E%3C/svg%3E ");
}

.output-management-multi-select-attachments {

  @extend .output-management-multi-select;

  .ng-select.ng-select-multiple .ng-select-container {
    overflow-y: auto;

    .ng-value-container {
      padding-left: 10px;
      max-height: 30px !important;
    }
  }
}

.output-management-multi-select-cc {

  @extend .output-management-multi-select;

  .ng-select.ng-select-multiple .ng-select-container {
    overflow-y: auto;

    .ng-value-container {
      padding-left: 10px;
      max-height: 30px !important;
    }
  }
}

.output-management-multi-select {

  .ng-select .ng-select-container {
    width: 100%;
    //border-bottom: 1px solid $border-color;
    //margin-left: 10px;
    z-index: 1 !important;
  }

  .ng-select .ng-arrow-wrapper {
    display: none;
  }

  .ng-select .ng-clear-wrapper {
    display: none;
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
    border: none;
    background: $case-summary-history-border-color;
    padding-top: 5px;
    float: right;
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
    background: $case-summary-history-border-color;
    padding-top: 5px;
  }
}

.regular-assessment-modal-window {
  .modal-dialog {
    max-width: 1150px;

    .modal-content {
      width: 1150px;
      height: 810px;
    }
  }
}

.output-message-confirm-close-modal-window {
  .modal-dialog {
    max-width: 400px;

    .modal-content {
      width: 400px;
      height: 168px;
    }
  }
}

.message-form {

 .ng-dropdown-panel .ng-dropdown-panel-items {
    max-height: 600px;
  }
}

.hover-tooltip {
  box-shadow: 0 0 11px -2px rgba(0, 0, 0, 0.75);
}

.case-basics-card,
.case-stakeholder {
  margin-left: 25px;
  margin-right: 25px;
  padding-top: 0;
}

.field-row {
  @extend .row;
  min-height: 63px;
}

.days-and-weeks {
  @extend .col-6;
  @extend .d-flex;

  >div:nth-child(1) {
    flex: 0 1 100px;
    margin-right: 15px;
  }

  >div:nth-child(2) {
    flex: 0 1 140px;
  }
}

.planed-travel-arrival-departure-column {
  @extend .col-6;
  @extend .d-flex;
  >div:nth-child(1) {
    flex: 0 1 120px;
    margin-right: 15px;
  }
  >div:nth-child(2) {
    flex: 0 1 120px;
  }
}

.modal-dialog {
  margin: 1.75rem (calc(1920px / 2) - 250px) !important;
}

.modal-content {
  border: 0 !important;
}

.modal-ontop {
  z-index: 99000 !important;
}

/*** Movable dialog ***/

// Set movable dialog z-index so we can have backdrop over the movable dialog when opening warn dialog
.cdk-overlay-container {
  z-index: 1050 !important;
}

.cdk-overlay-backdrop {
  z-index: 1001 !important;
}

// Fix for date picker, so it wont end behind movable dialog
.cdk-overlay-connected-position-bounding-box {
  z-index: 1100 !important;
}

// For bringing the active movable dialog to front
.dialog-to-front {
  z-index: 1001 !important;
}

// Make sure we can have wider dialogs than 80vw
.cdk-overlay-pane {
  max-width: 100vw !important;
}

.mat-dialog-container {
  padding: 0 !important;
  overflow-x: hidden !important;
  border: 1px white solid !important;
  border-radius: 10px !important;
}

.mat-dialog-content {
  max-height: 1000px !important;
}

.mat-dialog-hide {
  display: none !important;
}

.dialog-drag-area {
  position: absolute;
  width: calc(100% - 130px);
  height: 60px;
  z-index: 1005;
  display: block !important;
}

.dialog-drag-area-no-minimize {
  @extend .dialog-drag-area;
  width: calc(100% - 60px);
}

.mat-dialog-container {
  .ng-dropdown-panel.ng-select-top {
    bottom: unset !important;
  }
}

.modal-panel {
  background-color: white;
}

.char-count-warning {
  color: $now-color;
}

/** End **/

.dot {
  height: 4px;
  width: 4px;
  background-color: $checked-color;
  border-radius: 50%;
  display: inline-block;
}

.flag-icon {
  vertical-align: middle;
  width: 28px;
  height: 28px;
}
.supplier-invoice-score-map-details {
  min-width: 380px !important;
}


.case-medical-pre-assessment-button-popClass {
  min-width: 300px;
}

.case-info{
  .casetype-style-CT01 {
    background-color: $assistance-and-claims-green;
  }
  .casetype-style-CT02 {
    background-color: $assistance-and-claims-green;
  }
  .casetype-style-CT03 {
    background-color: $claims-yellow;
  }
  .casetype-style-CT04 {
    background-color: $batch-billing-light-blue;
  }
  .casetype-style-CT05 {
    background-color: $cost-control-purple;
  }
  .casetype-style-CT06 {
    background-color: $medical-pre-assessment-orange;
  }
  .casetype-style-CT07 {
    background-color: $assistance-and-claims-green;
  }
  .casetype-style-CT08 {
    background-color: $investigation-dark-blue;
  }
  .casetype-style-CT10 {
    background-color: $pre-departure-travel-service-orange;
  }
  .casetype-style-CT11 {
    background-color: $internal-coordination-dark-green;
  }
  .casetype-style-CT12 {
    background-color: $sanction-case-red;
  }
  .casetype-style-CT13 {
    background-color: $sanction-case-red;
  }
  .casetype-style-CT14 {
    background-color: $medical-flight-authorization-orange;
  }
  .casetype-style-CT15 {
    background-color: $internal-coordination-dark-green;
  }
  .casetype-style-CT99 {
    background-color: $awaiting-case-type-black;
  }
}

.redText {
  color: $now-color;
}

.economy-creditor-info-popover {
  width: 400px;
  max-width: 400px;
}

.economy-refund-supplier-invoice-menu {
  width: 270px;
  .popover-body {
    padding: 0;
  }
}
