::-webkit-scrollbar {
  width: 9px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0 $inactive-color;
  border-radius: 0;
}

::-webkit-scrollbar-thumb {
  background: $inactive-color;
  border-radius: 15px;
}

::-webkit-scrollbar-thumb:hover {
  background: $primary-color;
}
