@import "bootstrap/scss/mixins";
.button-with-dots {
  border-width: 1px;
  border-style: solid;
  width: 42px;
  border-color: $border-color;
  outline-width: 0px;
  height: 36px;
  background-color: white;
  border-radius: 3px;

  .dot {
    height: 4px;
    width: 4px;
    background-color: $checked-color;
    border-radius: 50%;
    display: inline-block;
    margin-left: 3px;
  }
}

.primary-button {
  background-color: $primary-color;
  border-width: 0px;
  border-radius: 3px;
  font-size: 16px;
  color: white;
  white-space: nowrap;
  padding: calc(1rem * 0.5) calc(1rem * 1.2) calc(1rem * 0.2) calc(1rem * 1.2);
}

.primary-button-white {
  @extend .primary-button;
  background-color: white;
  color: $primary-color;
  border: 1px solid $primary-color;
  padding: calc(1rem * 0.45) calc(1rem * 1.2) calc(1rem * 0.15) calc(1rem * 1.2);
}

.primary-button-inactive {
  @extend .primary-button;
  background-color: $inactive-color;
}

.cancel-button {
  background-color: white;
  border: 1px solid $primary-color;
  color: $primary-color;
  padding: .45rem 1.2rem .15rem;
  border-radius: 3px;
  font-size: 16px;
  &:disabled {
    color: $inactive-color;
    border-color: $inactive-color;
  }
}