.permission-disable {
  pointer-events: none !important;
  .ng-select-container,
  .checkmark,
  textarea,
  .selected-item-box,
  .input-ranged,
  button,
  .invalid-input-date {
    background-color: $disabled-color !important;
  }

  input {
      background-color: $disabled-color;
  }

  .ng-select-container {
    input {
      background-color: none ;
    }
  }

  .ng-input {
    input {
      background-color: none ;
    }
  }

  button {
    color: $inactive-color !important;
  }
}

.permission-hide {
  visibility: hidden !important;
}

.permission-remove {
  display: none !important;
}
