@import 'variables.scss';

.board-content {
  display: block;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
}

.board-narrowed {
  margin-right: 50px;
}
.board-full {
  margin-right: 50px;
}
