/* Snow effect */

$d: 40; // density
$w: 600; // grid size
$s: 10s; // speed

// Generate the snow image using radial gradients

$grad: ();
@for $i from 0 to $d {
  $v: random(4) + 2;
  $a: random(5) * 0.1 + 0.5;
  $grad: $grad,
    radial-gradient(
      $v + px $v + px at (random($w - $v * 2) + $v) + px (random($w - $v * 2) + $v) + px,
      rgba(255, 255, 255, $a) 50%,
      rgba(0, 0, 0, 0)
    ) !global;
}

//html {
//  height: 100%;
//  background: linear-gradient(#123 30%, #667);
//  overflow: hidden;
//}

.snow,
.snow:before,
.snow:after {
  position: absolute;
  top: -$w + px;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: $grad;
  background-size: $w + px $w + px;
  animation: snow $s linear infinite;
  content: '';
}

.snow:after {
  margin-left: calc(-#{$w} / 3) + px;
  opacity: 0.4;
  animation-duration: $s * 2;
  animation-direction: reverse;
  filter: blur(3px);
}

.snow:before {
  animation-duration: $s * 3;
  animation-direction: reverse;
  margin-left: calc(-#{$w} / 2) + px;
  opacity: 0.65;
  filter: blur(1.5px);
}

@keyframes snow {
  to {
    transform: translateY($w + px);
  }
}
