@import "variables.scss";
@import "scrollbar.scss";

.add-service-order {
  .left-column-base {
    width: 100%;
    padding-top: 1em;
  }

  .left-row {
    @extend .row;
    margin-left: 37px;
    margin-right: 37px;
    margin-bottom: 10px;
  }

  .left-column-container {
    width: 542px;
    margin-bottom: 10px;

    .adjustpadding {
      margin: 0 !important;
      padding-right: 6px !important;
      padding-left: 0 !important;
    }
  }

  .service-order-type-tabset {
    .tab-pane {
      min-height: 590px;
    }
  }

  .sub-component-placeholder {
    margin: 20px;

    .sub-component-container {
      border: 1px solid $border-color;

      .sub-title {
        font-size: 14px;
        font-family: Medium;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 3px;
        border-bottom: 1px solid $border-color;
      }

      .sub-title-table {
        font-size: 12px;
        white-space: nowrap;
        color: $checked-color;
      }

      .sub-name {
        font-size: 12px;
        white-space: nowrap;
        color: $checked-color;
      }

      .sub-content-container {
        padding: 15px;
        padding-top: 12px;

        .sub-content-title {
          font-size: 14px;
          font-family: Medium;
        }
      }
    }

    a:hover.no-hover {
      color: white;
      text-decoration: none;
    }

    button:disabled {
      background-color: $inactive-color;
      color: white;
      cursor: default;
    }
  }

  .sub-component-horizontal-line {
    width: 100%;
    height: 1px;
    background-color: $border-color;
  }

  .adjustpadding {
    margin: 0 !important;
    padding-right: 6px !important;
    padding-left: 0 !important;
  }

  .search-supplier-button {
    @extend .primary-button;
    margin-top: 20px;
  }

  .search-supplier-button-inactive {
    @extend .primary-button-inactive;
    margin-top: 20px;
  }

  .red-star {
    color: red;
    float: right;
  }

  .remarks {
    width: 502px;
    border-radius: 2px;
    border-color: $case-summary-history-border-color;
    border-style: solid;
    height: 61px;
  }

  .text {
    font-size: 12px;
    color: $checked-color;
  }

  .stakeholders {
    width: 390px;
    max-height: 107px;
    overflow-y: auto;
    margin-bottom: 10px;
    border-bottom: 1px solid $border-color;

    .stakeholder-gray {
      background-color: $alternate-table-row-color;
      @extend .stakeholder;
    }

    .stakeholder {
      width: 390px;
      height: 35px;
      overflow-x: hidden;
      border: 1px solid $border-color;
      border-bottom-style: none;
      font-size: 14px;

      .stakeholder-name {
        margin-left: 5px;
        margin-top: 6px;
      }

      .stakeholder-icon {
        margin-right: 7px;
      }

      // .stakeholder-gender-age {
      //   font-size: 14px;
      //   margin-top: 10px;
      //   float: left;
      // }

      // .stakeholder-icon {
      //   float: left;

      //   .icon {
      //     margin-left: 40px;
      //     margin-top: 5px;
      //   }
      // }
    }
  }
}
